export default class article {
  constructor (opt = {}) {
    this.target = opt.target;
  }
  tagLink () {
    const self = this;
    $(document).on('click', self.target, function (e) {
      e.preventDefault();
      const href = $(this).data('href');
      location.href = '/news/' + href;
    });
  }
}
