export default class toggle {
  constructor (opt = {}) {
    this.class = opt.class;
    this.trigger = opt.trigger;
    this.closest = opt.closest;
    this.target = opt.target;
  }
  slideToggle () {
    const self = this;
    $(document).on('click', self.trigger, function () {
      $(this).closest(self.closest).toggleClass(self.class).find(self.target).slideToggle();
    });
  }
}
