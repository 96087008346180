export default class nav {
  constructor (opt = {}) {
    this.target = opt.target;
  }
  removeElement () {
    const self = this;
    const target = document.querySelector(self.target);
    if (target !== null) {
      const targetAll = document.querySelectorAll(self.target);
      const pathname = location.pathname.replace('index.html', '');
      for (let i = 0; i < targetAll.length; i++) {
        const src = targetAll[i].getAttribute('href').replace('index.html', '');
        if (pathname === src) {
          const ele = targetAll[i].parentNode;
          ele.parentNode.removeChild(ele);
        }
      }
    }
  }
}
