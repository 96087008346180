import 'slick-carousel';
// import _common from './modules/common';
import _classOperation from './modules/class_operation';
import _toggle from './modules/toggle';
import _carousel from './modules/carousel';
import _background from './modules/background';
import _nav from './modules/nav';
import _parallax from './modules/parallax';
import _article from './modules/article';

// common
// const common = new _common();

// classOperation
const headerNav = new _classOperation({
  class: 'is-menuopen',
  target: '.js-menutrigger',
  element: 'html'
});
headerNav.clickSwich();

const headerResize = new _classOperation({
  class: 'is-menuopen',
  element: 'html',
  maxBreakPoint: 959
});
headerResize.windowResize('remove');

const header = new _classOperation({
  class: 'is-header-fixed',
  target: window,
  element: '.l-header',
  targetpoint: '300'
});
header.scrollSwich();

// carousel
const topCarousel = new _carousel({
  target: '.c-hero__carousel'
});
topCarousel.topHeroCarousel();

// background
const setBgImg = new _background({
  target: '.js-setbackground'
});
setBgImg.setBackgroundImage();

const panel1 = new _background({
  target: '.js-setbackground-panel-1',
  gradient: 'rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)'
});
panel1.setBackgroundImage();

const panel2 = new _background({
  target: '.js-setbackground-panel-2'
});
panel2.setBackgroundImage();

const panel3 = new _background({
  target: '.js-setbackground-panel-3',
  gradient: 'rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)'
});
panel3.setBackgroundImage();

// nav
const serviceNav = new _nav({
  target: '.js-service-nav'
});
serviceNav.removeElement();

// toggle
const toggle = new _toggle({
  class: 'is-toggle-open',
  trigger: '.js-toggle-trigger',
  closest: '.c-toggle',
  target: '.c-toggle__content'
});
toggle.slideToggle();

// parallax
const parallax = new _parallax({
  wrapper: '.c-scroll-img',
  target: '.js-parallax-img'
});
parallax.moveImage();

// article
const article = new _article({
  target: '.js-news__tag'
});
article.tagLink();
