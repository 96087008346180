export default class parallax {
  constructor (opt = {}) {
    this.wrapper = opt.wrapper;
    this.target = opt.target;
  }
  moveImage () {
    const self = this;
    if ($(self.target).length > 0) {
      const $wrap = $(self.wrapper);
      const $ele = $(self.target);
      $(window).scroll(function () {
        const wrapT = $wrap.offset().top;
        const scrollT = $(this).scrollTop();
        const winH = $(this).height();
        const startPoint = scrollT + winH;
        const overScroll = startPoint - wrapT;
        if (overScroll > 0) {
          const wrapH = $wrap.outerHeight();
          const imgH = $ele.outerHeight();
          const imgRest = imgH - wrapH;
          const movePer = imgRest / winH;
          const value = -(movePer * overScroll) + 'px';
          $ele.css('bottom', value);
        }
      });
    }
  }
}
