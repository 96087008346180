export default class carousel {
  constructor (opt = {}) {
    this.target = opt.target;
  }
  topHeroCarousel () {
    const self = this;
    if ($(self.target).length > 0) {
      $(self.target).slick({
        speed: 1000,
        fade: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4500,
        pauseOnFocus: false,
        pauseOnHover: false
      });
    }
  }
}
