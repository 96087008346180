export default class background {
  constructor (opt = {}) {
    this.target = opt.target;
    this.gradient = opt.gradient;
  }
  setBackgroundImage () {
    const self = this;
    const gradient = (self.gradient) ? 'linear-gradient(' + self.gradient + '),' : '';
    if ($(self.target).length > 0) {
      $(self.target).each(function () {
        let url = $(this).attr('data-image');
        $(this).css({
          'background-image': gradient + 'url(' + url + ')'
        });
      });
    }
  }
}
