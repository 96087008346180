export default class classOperation {
  constructor (opt = {}) {
    this.class = opt.class;
    this.target = opt.target;
    this.element = opt.element;
    this.targetpoint = opt.targetpoint || false; // 指定がなければ要素が画面中央に来たとき
    this.defaultAction = opt.defaultAction || false;
    this.oneTime = opt.oneTime || false; // 一度だけの実行か
    this.maxBreakPoint = opt.maxBreakPoint;
  }
  clickSwich () {
    const self = this;
    $(document).on('click', self.target, function (event) {
      if (!self.defaultAction) event.preventDefault();
      $(self.element).toggleClass(self.class);
    });
  }
  scrollSwich () {
    const self = this;
    if ($(self.element).length > 0) {
      $(self.target).scroll(function () {
        let top = $(this).scrollTop();
        let point = (!self.targetpoint)
          ? $(self.element).offset().top - ($(window).height() / 2)
          : self.targetpoint;

        if (point < top) {
          $(self.element).addClass(self.class);
        } else if (!self.oneTime) {
          $(self.element).removeClass(self.class);
        }
      });
    }
  }
  windowResize (type) {
    const self = this;
    $(window).on('resize', function () {
      const ww = $(this).width();
      if (ww > self.maxBreakPoint) {
        if (type === 'remove') {
          $(self.element).removeClass(self.class);
        }
      }
    });
  }
}
